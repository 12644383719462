<template>
  <section class="block paginate_block general">
    <CommonTitle :options="options_title" />
    <CommonTable :options="options_table" />
  </section>
</template>

<script>
import Axios from 'axios';
import CommonTable from '../common/CommonTable';
import CommonTitle from '../common/CommonTitle';

export default {
  name: 'UserMain',
  data() {
    return {
      options_title: {
        name: 'Игроки',
        id: 'user',
        actions: '',
      },
      options_table: {
        id: 'user',
        request: 'user?role=3',
        actions: 'editremove',
        ths: [
          { id: 'login', name: 'Логин', width: '15%', value: '', sort: false, search: true },
          { id: 'email', name: 'Почта', width: '15%', value: '', sort: true, search: true },
          { id: 'score', name: 'Баллы', width: '15%', value: '', sort: true, search: true },
          { id: 'type', name: 'Тип аккаунта', width: '15%', value: '', sort: true, search: true },
          { id: 'subscription', name: 'Дата окончания подписки', width: '20%', value: '', sort: true, search: true },
          { id: 'creation_date', name: 'Дата регистрации', width: '15%', value: '', sort: true, search: false },
          { id: '', name: '', width: '5%', value: '', sort: false, search: false },
        ],
        table: {
          data: []
        }
      }
    }
  },
  mounted() {
    Axios
      .get(`/api/user`, {
        headers: {
          token: this.$store.getters.token
        },
        params: {
          role: 3,
          page: 0,
          limit: 10
        }
      })
      .then(res => {
        this.options_table.table = {
          data: res.data.data,
          variables: ['login', 'email', 'score', 'type', 'subscription', 'creation_date'],
          page: 0,
          limit: 10,
          total: res.data.total
        }
      })
      .catch(err => {
        console.error(err.response);
      });

    this.$bus.$on('editScore', (result) => {
      const user = this.options_table.table.data.find(el => el._id === result.data._id);
      if (user) {
        user.score = result.data.score;
        user.type = result.data.type;
      }
    });
    this.$bus.$on('removeUser', (result) => {
      this.options_table.table.data = this.options_table.table.data.filter(el => el._id !== result.data);
    });
  },
  components: {
    CommonTitle,
    CommonTable
  }
}
</script>

<style>

</style>