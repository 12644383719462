<template>
  <UserMain />
</template>

<script>
import UserMain from '@/components/user/UserMain';

export default {
  name: 'User',
  components: {
    UserMain
  }
}
</script>

<style>

</style>